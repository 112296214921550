<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} Dealer</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteDealers()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="Description">Description</label>
        <input
          class="form-control"
          id="Description"
          name="Description"
          v-model="editingDealers.Description"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Lat">Lat</label>
        <input
          class="form-control"
          id="Lat"
          name="Lat"
          v-model="editingDealers.Lat"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Long">Long</label>
        <input
          class="form-control"
          id="Long"
          name="Long"
          v-model="editingDealers.Long"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="DistanceFromClient">DistanceFromClient</label>
        <input
          class="form-control"
          id="DistanceFromClient"
          name="DistanceFromClient"
          v-model="editingDealers.DistanceFromClient"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ContactEmail">ContactEmail</label>
        <input
          class="form-control"
          id="ContactEmail"
          name="ContactEmail"
          v-model="editingDealers.ContactEmail"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ContactNumber">ContactNumber</label>
        <input
          class="form-control"
          id="ContactNumber"
          name="ContactNumber"
          v-model="editingDealers.ContactNumber"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="OperatingHours">OperatingHours</label>
        <input
          class="form-control"
          id="OperatingHours"
          name="OperatingHours"
          v-model="editingDealers.OperatingHours"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Address">Address</label>
        <input
          class="form-control"
          id="Address"
          name="Address"
          v-model="editingDealers.Address"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Address">Slug(URL)</label>
        <input
          class="form-control"
          id="Slug"
          name="Slug"
          v-model="editingDealers.Slug"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="GroupId">GroupId</label>
        <input
          class="form-control"
          id="GroupId"
          name="GroupId"
          v-model="editingDealers.GroupId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ProvinceId">ProvinceId</label>
        <input
          class="form-control"
          id="ProvinceId"
          name="ProvinceId"
          v-model="editingDealers.ProvinceId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingDealers.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="OriginalId">OriginalId</label>
        <input
          class="form-control"
          id="OriginalId"
          name="OriginalId"
          v-model="editingDealers.OriginalId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        
         <ImageUploader
          label="Image"
          :dark="false"
          v-model="editingDealers.Image"
        />
      </div>
      <div class="col-md-4">
        <br />
        <b-form-checkbox id="Sales" v-model="editingDealers.Sales" name="Sales"
          >Sales</b-form-checkbox
        >
        <b-form-checkbox
          id="SellAllModels"
          v-model="editingDealers.SellAllModels"
          name="SellAllModels"
          >Sell all models</b-form-checkbox
        >
        <b-form-checkbox
          id="Service"
          v-model="editingDealers.Service"
          name="Service"
          >Service</b-form-checkbox
        >
        <b-form-checkbox
          id="CommercialSales"
          v-model="editingDealers.CommercialSales"
          name="Commercial Sales"
          >Commercial Sales</b-form-checkbox
        >
        <b-form-checkbox
          id="CommercialService"
          v-model="editingDealers.CommercialService"
          name="Commercial Service"
          >Commercial Service</b-form-checkbox
        >
        <b-form-checkbox
          id="CommercialParts"
          v-model="editingDealers.CommercialParts"
          name="Commercial Parts"
          >Commercial Parts</b-form-checkbox
        >
      </div>

      <div class="col-md-4" v-if="editingDealers.SellAllModels == false">
        <b
          >Can sell Models:
          <button @click="selectAll()" type="button" class="btn btn-success">
            Select All
          </button></b
        >
        <br />
        <br />
        <div class="models">
          <b-form-checkbox
            v-for="item in vehicles"
            :key="item.Id"
            :id="item.Name"
            v-model="item.CanSell"
            >{{ item.Name }}</b-form-checkbox
          >
        </div>
      </div>
    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
import ImageUploader from '@/components/custom/ImageUploader.vue';
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditDealers",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
      vehicles: null,
    };
  },
  components: {
    FileUploader,
    ImageUploader,
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingDealers));
    this.ImageBytes = this.editingDealers.Image;
    this.getVehicleModels();
  },

  props: ["editingDealers", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditDealers");
    },
    selectAll() {
      var vehicles = [];
      for (const iterator of this.vehicles) {
        iterator.CanSell = true;
        vehicles.push(iterator);
      }
      this.vehicles = vehicles;
    },
    getVehicleModels() {
      var payload = {
        success: (response) => {
          this.vehicles = response.data.sort((a, b) =>
            a.Name > b.Name ? 1 : -1
          );
          
          for (const iterator of this.vehicles) {
            iterator.CanSell = false;
          }
          if (this.editingDealers && this.editingDealers.ExcludeModels) {
            var cannotSell = this.editingDealers.ExcludeModels.split(",");

            for (const iterator of this.vehicles) {
              iterator.CanSell = cannotSell.indexOf(iterator.Id.toString()) == -1;
            }
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleModels", payload);
    },
    getExludedModels() {
      return this.vehicles
        .filter((g) => g.CanSell == false)
        .map((g) => g.Id)
        .join(",");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingDealers));
      
      data.ExcludeModels = this.getExludedModels();
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editDealersSuccess");
          } else {
            this.$emit("addDealersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editDealers", payload);
      } else {
        this.$store.dispatch("addDealers", payload);
      }
      
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteDealers: function () {
      var payload = {
        data: this.editingDealers,
        success: (response) => {
          this.$emit("editDealersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteDealers", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
