<template>
  <div class="card">
    <div class="container">
      <b-modal ref="editDealersModal" hide-footer>
        <editDealers
          :editMode="editMode"
          :editingDealers="currentDealers"
          @closeEditDealers="toggleEditDealers()"
          @editDealersSuccess="editDealersSuccess()"
          @addDealersSuccess="addDealersSuccess()"
        ></editDealers>
      </b-modal>
      <div class="row">
        
        <h2 class="mt-3">Dealers</h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddDealers()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />
        <DataBlocks
          @itemSelected="itemSelected"
          :data="items"
          :fields="['Description']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import editDealers from "../../components/editDealers";
import VueNotifications from "vue-notifications";
import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      items: [],
      addDealersOpen: false,
      editDealersOpen: false,
      currentDealers: {},
      editMode: false,
    };
  },
  components: {
    editDealers,
    DataBlocks,
  },
  created: function () {
    this.getDealers();
    this.addDealersOpen = false;
    this.editDealersOpen = false;
  },
  methods: {
    itemSelected(item) {
      this.editMode = true;
      this.currentDealers = item;
      this.toggleEditDealers();
      this.editDealersOpen = !this.editDealersOpen;
    },
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getDealers() {
      var payload = {
        success: (response) => {
          this.items = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getDealers", payload);
    },
    editItem(Dealers) {
      this.toggleEditDealers();
      this.currentDealers = Dealers;
    },
    toggleAddDealers() {
      this.editMode = false;
      this.currentDealers = {};

      this.$refs.editDealersModal.hide();

      this.$refs.editDealersModal.show();

      this.addDealersOpen = !this.addDealersOpen;
    },
    addDealersSuccess() {
      this.$refs.editDealersModal.hide();
      miniToastr["success"]("Dealers Added", "Success", 1000, null);
      this.getDealers();
    },
    toggleEditDealers() {
      this.$refs.editDealersModal.hide();

      this.$refs.editDealersModal.show();

      this.editDealersOpen = !this.editDealersOpen;
    },
    editDealersSuccess() {
       this.$refs.editDealersModal.hide();
      miniToastr["success"]("Dealers Edited", "Success", 1000, null);
      this.getDealers();
    },
  },
};
</script>
<style scoped>
.DealersThumbnail {
  height: 50px;
}
</style>
